.chart-select-container-nrb {
  padding: 25px 15px;
}
.chart-select-container-nrb .card {
  border-radius: 0;
}
.chart-select-container-nrb .card .card-header {
  font-weight: bold;
  padding: 5px;
}
.chart-select-container-nrb .filter-row {
  margin-bottom: 10px;
}
.chart-select-container-nrb .btn--small-action {
  margin: 10px 10px 0 5px;
  padding: 8px;
  line-height: 14px;
  height: 34px;
  width: 34px;
}
.chart-select-container-nrb .highcharts-container {
  margin-left: auto;
  margin-right: auto;
}
.chart-select-container-nrb .chart-widget__top-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 70px;
}
.chart-select-container-nrb .chart-widget__top-panel .chart-widget__title {
  font-size: 16px !important;
  font-weight: 700;
}
.chart-select-container-nrb .chart-widget__top-panel .chart-widget__buttons {
  display: flex;
  column-gap: 16px;
}
.chart-select-container-nrb .chart-widget-container .chart-widget__top-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}
.chart-select-container-nrb .chart-widget-container .chart-widget__top-panel .chart-widget__title {
  font-size: 16px !important;
  font-weight: 700;
}
.chart-select-container-nrb .chart-widget-container .chart-widget__top-panel .chart-widget__buttons {
  display: flex;
  column-gap: 16px;
}
.chart-select-container-nrb .chart-widget-container .text-widget {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px;
}
.chart-select-container-nrb .chart-widget-container .text-widget .text-widget-value {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  white-space: nowrap;
}
.chart-select-container-nrb .chart-widget-container .text-widget .text-widget-title {
  font-size: 30px;
}
.chart-select-container-nrb .widget-loading-container {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.chart-widget__no-data-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.chart-widget__no-data-found .no-data-found__title {
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: 700;
  color: #212121;
}
.chart-widget__no-data-found .no-data-found__message {
  font-size: 16px;
  color: #757575;
}
