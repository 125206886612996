.builderPanel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  height: 64px;
  background: #ffffff;
  border-bottom: 1px solid #e4e4e4;
}
.builderPanel .titleWrapper {
  display: flex;
  gap: 5px;
  align-items: center;
}
.builderPanel .titleWrapper h1 {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 320px;
}
.builderPanel .titleWrapper .btn:disabled {
  cursor: not-allowed;
}
.builderPanel .titleWrapper .edit-title-btn {
  padding: 0;
  width: 44px;
  height: 32px;
  background: #fff;
  border-radius: 4px;
}
.builderPanel .titleWrapper .edit-title-btn:hover, .builderPanel .titleWrapper .edit-title-btn:active, .builderPanel .titleWrapper .edit-title-btn:focus {
  background: #E4E4E4;
  border: 1px solid #E4E4E4;
}
.builderPanel .titleWrapper .suggested-tag {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 20px;
  border: 1px solid #597EF7;
  border-radius: 2px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #1D39C4;
}
.builderPanel .controlWrapper {
  display: flex;
  gap: 16px;
  align-items: center;
}

.actionDropdown {
  margin-right: -16px;
}
.actionDropdown .elmo-dropdown__menu-item {
  gap: 8px;
  font-size: 14px !important;
}
