.selectFormModal {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;

  .panelContainer {
    display: flex;
    column-gap: 22px;
    justify-content: space-between;
    align-items: center;
    margin: 28px 0;
    line-height: 32px;

    &:first-child {
      margin-top: 0;
    }

    .search {
      flex: 1;
      max-width: 404px;
    }

    .btn-view-wrapper {
      flex: 1;

      a {
        cursor: pointer;
      }
    }
  }

  .secure-form-tooltip {
    margin-left: 14px;
  }

  .secure-form-icon {
    margin-top: -3px;
    height: 18px;
  }
}
