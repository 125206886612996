.grid-container .widget-cell {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1), 0 1px 6px rgba(0, 0, 0, 0.14);
  border-radius: 5px;
  overflow: hidden;
}
.grid-container .widget-cell:hover, .grid-container .widget-cell:active {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.16), 0 10px 10px rgba(0, 0, 0, 0.14);
}
.grid-container .widget-cell:hover > .react-resizable-handle, .grid-container .widget-cell:active > .react-resizable-handle {
  opacity: 1;
}
.grid-container .widget-cell:hover .widget-drag-icon, .grid-container .widget-cell:active .widget-drag-icon {
  opacity: 1 !important;
}
.grid-container .widget-cell:active {
  border: 1px solid #19579F;
}
.grid-container .widget-cell .report-result {
  overflow: hidden;
}

.dashboard-container {
  padding-left: 17px;
  width: 1800px;
}
.dashboard-container .dashboard-buttons {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.dashboard-container .dashboard-buttons .dashboard-right-buttons {
  display: flex;
  align-items: center;
}
.dashboard-container .dashboard-buttons .save-status {
  font-style: italic;
  margin-right: 10px;
}
.dashboard-container .dashboard-buttons .elmo-dropdown .elmo-btn__label {
  font-weight: bold;
}
.dashboard-container .dashboard-buttons .saveLayoutButton .elmo-btn__label {
  font-size: 16px;
}
.dashboard-container .dashboard-buttons .saveLayoutButton--notSaved .elmo-btn__label, .dashboard-container .dashboard-buttons .saveLayoutButton--notSaved .elmo-btn__icon {
  color: #19579F;
}
.dashboard-container .dashboard-buttons .saveLayoutButton--notSaved:hover .elmo-btn__label, .dashboard-container .dashboard-buttons .saveLayoutButton--notSaved:hover .elmo-btn__icon {
  color: #fff;
}
.dashboard-container .dashboard-buttons .divider {
  width: 1px;
  height: 32px;
  margin: 0 8px;
  background-color: rgba(0, 0, 0, 0.670588);
}
.dashboard-container .dashboard-buttons .exportPdfButton:hover:not(:disabled) path {
  fill: #fff;
  fill-opacity: 1;
}
.dashboard-container .dashboard-buttons .exportPdfButton--active path {
  fill: #19579f;
  fill-opacity: 1;
}
.dashboard-container .dashboard-buttons .exportPdfStatus {
  margin-left: 10px;
  font-size: 16px;
  color: #212529;
}
.dashboard-container .status-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.dashboard-container .status-container .status-name {
  font-weight: bold;
}
.dashboard-container .status-container .status-select {
  width: 250px;
  margin-left: 10px;
}

.dashboardsDropdown .elmo-dropdown__menu {
  min-width: 368px;
}
.dashboardsDropdown .elmo-dropdown__button {
  font-size: 19px;
  color: #000;
}
.dashboardsDropdown .elmo-dropdown__button:hover, .dashboardsDropdown .elmo-dropdown__button:focus, .dashboardsDropdown .elmo-dropdown__button:active {
  color: #000;
  background-color: rgba(0, 0, 0, 0.14);
}
.dashboardsDropdown .elmo-dropdown__button .elmo-btn__label {
  font-size: 18px;
}
