.new-filter-select-container .filter-on, .new-filter-select-container .filter-type, .new-filter-select-container .filter-value {
  min-height: 32px;
  width: 100%;
}
.new-filter-select-container .filter-on .form-control, .new-filter-select-container .filter-type .form-control, .new-filter-select-container .filter-value .form-control {
  height: 32px;
}
.new-filter-select-container .filter-on .form-control:disabled, .new-filter-select-container .filter-type .form-control:disabled, .new-filter-select-container .filter-value .form-control:disabled {
  background-color: #f6f6f6;
  color: #999999;
}
.new-filter-select-container .filter-value .elmo-input, .new-filter-select-container .elmo-datepicker .elmo-input {
  height: 32px !important;
  border: 1px solid #D9D9D9 !important;
  border-radius: 4px;
}
.new-filter-select-container .filter-value .elmo-input:read-only, .new-filter-select-container .elmo-datepicker .elmo-input:read-only {
  background-color: #f6f6f6;
  color: rgb(153, 153, 153);
}
.new-filter-select-container .filter-value.datepicker-override .label-container .label, .new-filter-select-container .filter-value.daterangepicker-override .label-container .label {
  display: block;
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25;
}
.new-filter-select-container .filter-value.datepicker-override .crt-date-range .elmo-row .elmo-col:first-child, .new-filter-select-container .filter-value.daterangepicker-override .crt-date-range .elmo-row .elmo-col:first-child {
  padding-right: 8px;
}
.new-filter-select-container .filter-value.datepicker-override .crt-date-range .elmo-row .elmo-col:last-child, .new-filter-select-container .filter-value.daterangepicker-override .crt-date-range .elmo-row .elmo-col:last-child {
  padding-left: 8px;
}
.new-filter-select-container .filter-value.datepicker-override .crt-date-range .elmo-date-range__start, .new-filter-select-container .filter-value.daterangepicker-override .crt-date-range .elmo-date-range__start {
  margin-right: 0;
}
.new-filter-select-container .filter-value.datepicker-override .elmo-datepicker__input-wrapper input, .new-filter-select-container .filter-value.daterangepicker-override .elmo-datepicker__input-wrapper input {
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  padding: 0 8px;
  color: #212121;
  font-size: 0.875rem;
  font-weight: 400;
}
.new-filter-select-container .filters-form-field .label-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.new-filter-select-container .filters-form-field .label-container .label {
  margin-bottom: 4px;
  font-weight: 500;
}
.new-filter-select-container .filters-form-field .label-container div:last-child {
  display: flex;
  align-items: center;
}
.new-filter-select-container .filters-form-field .label-container .elmo-spinner svg {
  width: 15px;
  height: 15px;
}
.new-filter-select-container .filter-groups-wrapper {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.new-filter-select-container .filter-groups-wrapper.border-bottom-radius-large {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.new-filter-select-container .or-filter-group-badge {
  margin: -10px 0;
}
.new-filter-select-container .or-filter-group-badge + .filter-groups-wrapper {
  border-top: none;
}
.new-filter-select-container .add-filter-group-wrapper {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
