span.elmo-badge.elmo-badge--override {
  &.elmo-badge--info {
    background-color: #1765ad;
  }

  &.elmo-badge--default {
    height: 20px;
    min-width: 18px;
    margin: -1px 0;

    .elmo-badge__label {
      padding-left: 2px;
      padding-right: 2px;
    }
  }

  &.elmo-badge--light {
    background-color: #D6E4FF;
    color: #212121;
  }
}