.suggestions-container-nrb button.elmo-btn.elmo-btn--override {
  width: 44px;
}

.suggestions-popover-nrb {
  width: 500px;
  max-width: 500px !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px !important;
  border: 1px solid #D9D9D9 !important;
}
.suggestions-popover-nrb .popover-header {
  display: flex;
  background: #fff;
  border-bottom: 0;
  padding: 22px 22px 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.suggestions-popover-nrb .popover-header h2 {
  flex-grow: 1;
  height: 32px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 32px;
  margin-bottom: 0;
}
.suggestions-popover-nrb .popover-body {
  padding: 0 23px 32px 23px;
  background-color: #fff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.suggestions-popover-nrb .arrow::before {
  border-right-color: #D9D9D9 !important;
}
