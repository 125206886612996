.drilldown-modal .drilldown-modal-data table th {
  position: sticky;
  top: 0;
  z-index: 1;
}
.drilldown-modal .drilldown-modal-data .table-responsive {
  max-height: 390px;
}
.drilldown-modal .new-pagination-wrapper {
  position: absolute;
  bottom: 10px;
}
.drilldown-modal .export-btn {
  margin-bottom: 10px;
}
