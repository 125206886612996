.rc-calendar {
  padding: 8px;
}
.rc-calendar .rc-calendar-year-panel-last-decade-cell .rc-calendar-year-panel-year,
.rc-calendar .rc-calendar-year-panel-next-decade-cell .rc-calendar-year-panel-year,
.rc-calendar .rc-calendar-decade-panel-last-century-cell .rc-calendar-decade-panel-decade,
.rc-calendar .rc-calendar-decade-panel-next-century-cell .rc-calendar-decade-panel-decade {
  color: rgba(0, 0, 0, 0.25) !important;
}

.crt-datepicker.elmo-datepicker .elmo-input {
  font-size: 14px;
  line-height: 20px;
  padding: 0.375rem 0.75rem;
  height: 34px;
  color: #495057;
  border: 1px solid #ced4da;
  /* Hide the X button that shows up by default on Internet Explorer */
}
.crt-datepicker.elmo-datepicker .elmo-input::-ms-clear {
  width: 0;
  height: 0;
  display: none;
}
.crt-datepicker.elmo-datepicker .btn-clear {
  width: 34px;
  height: 34px;
  padding: 4px 4px 8px 4px;
  color: #495057;
  background: rgba(255, 255, 255, 0);
}
.crt-datepicker.elmo-datepicker .btn-clear svg {
  font-size: 20px;
}
.crt-datepicker.elmo-datepicker .btn-clear {
  right: 0;
}

.rc-calendar {
  padding: 8px;
}
.rc-calendar .rc-calendar-year-panel-last-decade-cell .rc-calendar-year-panel-year,
.rc-calendar .rc-calendar-year-panel-next-decade-cell .rc-calendar-year-panel-year,
.rc-calendar .rc-calendar-decade-panel-last-century-cell .rc-calendar-decade-panel-decade,
.rc-calendar .rc-calendar-decade-panel-next-century-cell .rc-calendar-decade-panel-decade {
  color: rgba(0, 0, 0, 0.25) !important;
}

.crt-date-range {
  width: 100%;
}
.crt-date-range .elmo-date-range > .row {
  margin: 0;
}
.crt-date-range .elmo-date-range > .row > .col {
  max-width: 50%;
  padding: 0;
  margin: 0;
  flex: 1 0 0;
}
.crt-date-range .elmo-date-range__start {
  margin-right: 5px;
}
.crt-date-range .elmo-date-range .elmo-datepicker .elmo-input {
  font-size: 14px;
  line-height: 20px;
  padding: 0.375rem 0.75rem;
  height: 34px;
  color: #495057;
  border: 1px solid #ced4da;
  /* Hide the X button that shows up by default on Internet Explorer */
}
.crt-date-range .elmo-date-range .elmo-datepicker .elmo-input::-ms-clear {
  width: 0;
  height: 0;
  display: none;
}
.crt-date-range .elmo-date-range .elmo-datepicker .btn-clear {
  width: 34px;
  height: 34px;
  padding: 4px 4px 8px 4px;
  color: #495057;
  background: rgba(255, 255, 255, 0);
}
.crt-date-range .elmo-date-range .elmo-datepicker .btn-clear svg {
  font-size: 20px;
}
.crt-date-range .elmo-date-range .elmo-datepicker .btn-clear {
  right: 0;
}
