.new-pagination-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 24px 0 33px 0;
}
.new-pagination-wrapper .items-per-page-select {
  width: 50px;
}
.new-pagination-wrapper > * {
  margin-left: 10px;
}
.new-pagination-wrapper .page-item .page-link {
  padding: 5px;
}
.new-pagination-wrapper .page-item:not(.active) .page-link {
  color: #212529;
}
.new-pagination-wrapper .page-item.disabled .page-link {
  color: #b3b2b3;
}
.new-pagination-wrapper .page-item .elmo-icon--size-md .elmo-icon__svg {
  font-size: 20px;
}
