.elmo-modal.elmo-modal--override.elmo-modal--dialog .elmo-modal__content {
  border-radius: 16px;
  max-height: 70%;
}
.elmo-modal.elmo-modal--override.elmo-modal--dialog .elmo-modal__body {
  padding: 32px;
}
.elmo-modal.elmo-modal--override.is-auto-scroll .elmo-modal__body {
  scroll-behavior: auto;
}
@media (min-width: 1024px) {
  .elmo-modal.elmo-modal--override .elmo-modal__content {
    border-radius: 16px;
  }
}
.elmo-modal.elmo-modal--override .elmo-modal__header {
  border-bottom: 1px solid #e4e4e4;
  column-gap: 8px;
}
@media (min-width: 1024px) {
  .elmo-modal.elmo-modal--override .elmo-modal__header {
    padding: 24px 32px;
  }
}
.elmo-modal.elmo-modal--override .elmo-modal__header .elmo-modal__header-title {
  flex: 1;
}
.elmo-modal.elmo-modal--override .elmo-modal__header .elmo-modal__header-title .elmo-typography {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  line-height: 32px;
}
.elmo-modal.elmo-modal--override .elmo-modal__header .elmo-modal__header-close {
  margin: 0;
}
.elmo-modal.elmo-modal--override .elmo-modal__header .elmo-modal__header-close button.elmo-btn {
  height: 32px;
  min-width: 0;
  line-height: 14px;
  padding: 0 12px;
  border-radius: 4px;
  width: 32px;
  padding: 0;
}
.elmo-modal.elmo-modal--override .elmo-modal__header .elmo-modal__header-close button.elmo-btn .elmo-btn__label {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
}
.elmo-modal.elmo-modal--override .elmo-modal__header .elmo-modal__header-close button.elmo-btn:focus, .elmo-modal.elmo-modal--override .elmo-modal__header .elmo-modal__header-close button.elmo-btn:hover, .elmo-modal.elmo-modal--override .elmo-modal__header .elmo-modal__header-close button.elmo-btn:active, .elmo-modal.elmo-modal--override .elmo-modal__header .elmo-modal__header-close button.elmo-btn.active {
  background-color: transparent;
}
.elmo-modal.elmo-modal--override .elmo-modal__header .elmo-modal__header-close button.elmo-btn.elmo-btn--icon > .elmo-btn__icon {
  z-index: 1;
}
.elmo-modal.elmo-modal--override .elmo-modal__header .elmo-modal__header-close button.elmo-btn.elmo-btn--icon > .elmo-btn__icon + .elmo-btn__label {
  margin-right: 0;
  margin-left: 8px;
}
.elmo-modal.elmo-modal--override .elmo-modal__header .elmo-modal__header-close button.elmo-btn:hover:focus .elmo-btn__focused, .elmo-modal.elmo-modal--override .elmo-modal__header .elmo-modal__header-close button.elmo-btn:focus:not(:focus-visible) .elmo-btn__focused {
  opacity: 0;
}
.elmo-modal.elmo-modal--override .elmo-modal__header .elmo-modal__header-close button.elmo-btn.elmo-btn--default {
  color: #212121;
}
.elmo-modal.elmo-modal--override .elmo-modal__header .elmo-modal__header-close button.elmo-btn.elmo-btn--default:not(.elmo-btn--is-disabled) {
  border: 1px solid #e4e4e4;
}
.elmo-modal.elmo-modal--override .elmo-modal__header .elmo-modal__header-close button.elmo-btn.elmo-btn--default .elmo-btn__focused {
  background-color: rgba(0, 0, 0, 0.03);
}
.elmo-modal.elmo-modal--override .elmo-modal__header .elmo-modal__header-close button.elmo-btn.elmo-btn--default .elmo-btn__hover {
  background-color: #ededed;
}
.elmo-modal.elmo-modal--override .elmo-modal__header .elmo-modal__header-close button.elmo-btn.elmo-btn--default .elmo-btn__pressed {
  background-color: #e4e4e4;
}
.elmo-modal.elmo-modal--override .elmo-modal__header .elmo-modal__header-close button.elmo-btn.elmo-btn--default.elmo-btn--text, .elmo-modal.elmo-modal--override .elmo-modal__header .elmo-modal__header-close button.elmo-btn.elmo-btn--default.elmo-btn--is-text {
  border: none;
}
.elmo-modal.elmo-modal--override .elmo-modal__header .elmo-modal__header-close button.elmo-btn.elmo-btn--default.active .elmo-btn__pressed {
  opacity: 1;
}
.elmo-modal.elmo-modal--override .elmo-modal__header .elmo-modal__header-close button.elmo-btn.elmo-btn--primary:not(.elmo-btn--is-disabled) {
  background-color: #1765ad !important;
}
.elmo-modal.elmo-modal--override .elmo-modal__header .elmo-modal__header-close button.elmo-btn.elmo-btn--primary .elmo-btn__focused {
  background-color: rgba(0, 0, 0, 0.15);
}
.elmo-modal.elmo-modal--override .elmo-modal__header .elmo-modal__header-close button.elmo-btn.elmo-btn--primary .elmo-btn__hover {
  background-color: #164c7e;
}
.elmo-modal.elmo-modal--override .elmo-modal__header .elmo-modal__header-close button.elmo-btn.elmo-btn--primary .elmo-btn__pressed {
  background-color: #15395b;
}
.elmo-modal.elmo-modal--override .elmo-modal__header .elmo-modal__header-close button.elmo-btn.elmo-btn--is-disabled {
  color: #9E9E9E !important;
}
.elmo-modal.elmo-modal--override .elmo-modal__header .elmo-modal__header-close button.elmo-btn.elmo-btn--is-disabled,
.elmo-modal.elmo-modal--override .elmo-modal__header .elmo-modal__header-close button.elmo-btn.elmo-btn--is-disabled .elmo-btn__hover,
.elmo-modal.elmo-modal--override .elmo-modal__header .elmo-modal__header-close button.elmo-btn.elmo-btn--is-disabled .elmo-btn__pressed {
  background-color: #ededed !important;
}
.elmo-modal.elmo-modal--override .elmo-modal__header .elmo-modal__header-close button.elmo-btn.icon-btn {
  width: 32px;
  padding: 0;
}
@media (max-width: 1023px) {
  .elmo-modal.elmo-modal--override .elmo-modal__header .elmo-modal__header-primary-btn {
    display: flex;
    column-gap: 8px;
  }
}
.elmo-modal.elmo-modal--override .elmo-modal__footer {
  padding: 24px 32px;
  border-top: 1px solid #e4e4e4;
}
.elmo-modal.elmo-modal--override .elmo-modal__footer .elmo-modal__footer-primary-btn {
  display: flex;
  column-gap: 8px;
  margin-bottom: 0;
}
.elmo-modal.elmo-modal--override .elmo-modal__footer .elmo-modal__footer-primary-btn button.elmo-btn {
  height: 40px;
  padding: 0 16px;
}
.elmo-modal.elmo-modal--override.header-close-disabled .elmo-modal__header-close {
  display: none;
}
