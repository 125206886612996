.filter-select-container .card {
  border-width: 1px 1px 0 0;
  border-radius: 0;
}
.filter-select-container .card .card-header {
  font-weight: bold;
  padding: 5px 10px;
  background: #e0e0e0;
}
.filter-select-container .card .card-header i {
  color: #424242;
  margin-right: 10px;
}
.filter-select-container .card .card-body {
  padding: 10px;
}
.filter-select-container .collapse.show {
  border-bottom: 1px solid #dce2e5;
}
.filter-select-container .filter-row {
  margin-bottom: 10px;
}
.filter-select-container .filter-row .filter-on, .filter-select-container .filter-row .filter-type, .filter-select-container .filter-row .filter-value {
  min-width: 150px;
}
.filter-select-container .filter-row .filter-remove {
  min-width: 50px;
}
