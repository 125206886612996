#PopoverContainer .elmo-popover-base__content--is-on-body {
  z-index: 1051;
}

.add-schedule-modal .modal-title .title {
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  line-height: 34px;
  letter-spacing: -0.3px;
  color: rgba(0, 0, 0, 0.87);
}
.add-schedule-modal .modal-title .subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: rgba(0, 0, 0, 0.67);
}
.add-schedule-modal .addScheduleModal__alert {
  font-size: 16px;
}
