.pagination-wrapper {
  margin: 10px;
}
.pagination-wrapper .items-per-page-select {
  width: 50px;
}
.pagination-wrapper > * {
  margin-left: 10px;
}
.pagination-wrapper .page-item:not(.active) .page-link {
  color: #212529;
}
.pagination-wrapper .page-item.disabled .page-link {
  color: #b3b2b3;
}
