.chart-select-container {
  padding-bottom: 10px;
  padding-right: 4px;
  border-style: solid;
  border-color: #dce2e5;
  border-width: 0 0 1px 0;
}
.chart-select-container .card {
  border-radius: 0;
}
.chart-select-container .card .card-header {
  font-weight: bold;
  padding: 5px;
}
.chart-select-container .filter-row {
  margin-bottom: 10px;
}
.chart-select-container .btn--small-action {
  margin: 10px 10px 0 5px;
  padding: 8px;
  line-height: 14px;
  height: 34px;
  width: 34px;
}
.chart-select-container .highcharts-container {
  margin-left: auto;
  margin-right: auto;
}
.chart-select-container .chart-widget-container {
  padding: 10px;
}
.chart-select-container .chart-widget-container .text-widget {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px;
}
.chart-select-container .chart-widget-container .text-widget .text-widget-value {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  white-space: nowrap;
}
.chart-select-container .chart-widget-container .text-widget .text-widget-title {
  font-size: 30px;
}
.chart-select-container .widget-loading-container {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.no-data-found-report-builder,
.no-data-found-dashboard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: 1;
  font-size: 14px;
  padding: 100px 0;
  width: 100%;
}
.no-data-found-report-builder .block-icon,
.no-data-found-dashboard .block-icon {
  margin-bottom: 10px;
  height: 40px;
  width: 100%;
  max-height: 25%;
}
.no-data-found-report-builder .block-icon .elmo-icon__svg,
.no-data-found-dashboard .block-icon .elmo-icon__svg {
  width: 100%;
  height: 100%;
}

.no-data-found-dashboard {
  height: calc(100% - 40px);
  padding: 0;
}
