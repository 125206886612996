.react-resizable {
  position: relative;
}

.react-resizable-handle {
  opacity: 0;
  position: absolute;
  width: 24px;
  height: 24px;
  bottom: 0;
  right: 0;
  background: url("../../assets/icon/resize-gray.svg");
  background-position: bottom right;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
}
.react-resizable-handle:hover, .react-resizable-handle:active {
  background: url("../../assets/icon/resize.svg");
}
