.user-select .user-select-label {
  overflow: hidden;
}
.user-select .user-select-label > * {
  flex-shrink: 0;
}
.user-select .user-select-label .initials {
  background: #E6F1FF;
  color: #19579F;
  width: 28px;
  height: 28px;
  text-transform: uppercase;
  border-radius: 50%;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 1.25px;
  padding-left: 2px;
  margin-right: 10px;
}
.user-select .user-select-label .name, .user-select .user-select-label .email {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}
.user-select .user-select-label .name {
  color: rgba(0, 0, 0, 0.87);
}
.user-select .user-select-label .email {
  color: rgba(0, 0, 0, 0.45);
}
.user-select .selected-user {
  margin-top: 10px;
}
.user-select .selected-user .remove-button {
  margin-left: 10px;
}
.user-select .react-select__menu {
  position: absolute;
  top: -230px;
  left: 0;
  height: 200px;
}
