.elmo-modal--fs16 {
  font-size: 16px;
}

.elmo-modal--not-scrollable .elmo-modal__content {
  min-height: 460px;
  overflow: visible;
}
.elmo-modal--not-scrollable .elmo-modal__content .elmo-modal__body {
  overflow: visible;
}
.elmo-modal--not-scrollable .elmo-modal__content .elmo-modal__body .react-select__menu-list {
  max-height: 175px;
}

.btn--share {
  margin: 10px 0 0 5px;
}
