.suggestion-content-nrb {
  font-family: "Open Sans", sans-serif;
}
.suggestion-content-nrb .selection .badge {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #1D39C4;
  border: 1px solid #597EF7;
  border-radius: 2px;
  margin: 0 5px 5px 0;
}
.suggestion-content-nrb h2 {
  font-size: 14px;
}
.suggestion-content-nrb hr {
  width: calc(100% + 46px);
  margin-left: -23px;
  border-top: 1px solid #E4E4E4;
  margin-top: 24px;
  margin-bottom: 37px;
}
.suggestion-content-nrb .list-group {
  max-height: 242px;
  overflow-y: auto;
  cursor: pointer;
}
.suggestion-content-nrb .list-group .list-group-item {
  padding: 12px;
  margin-bottom: 0;
  border: 0;
  border-bottom: 1px solid #E4E4E4;
  border-radius: 0;
}
.suggestion-content-nrb .list-group .list-group-item:hover {
  background: #eeeeee;
}
.suggestion-content-nrb .list-group .list-group-item .suggestion-item {
  display: flex;
  align-items: center;
}
.suggestion-content-nrb .list-group .list-group-item.suggestion-active {
  background: #EDEDED;
}
.suggestion-content-nrb .suggestion-title-with-tooltip {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  margin-right: 3px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #212121;
}
.suggestion-content-nrb .suggestion-title-with-tooltip .elmo-icon--size-md .elmo-icon__svg {
  font-size: 16px;
}
.suggestion-content-nrb .no-suggestions {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 242px;
  border-radius: 8px;
  text-align: center;
  padding: 0 120px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}
.suggestion-content-nrb .no-suggestions .heading {
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: 700;
}
.suggestion-content-nrb .no-suggestions .content {
  color: #757575;
}
