.report-transformation {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;

  h1 {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 18px;
  }

  .report-transformation-table {
    display: flex;
    flex-direction: column;
    border: 1px solid #E4E4E4;
    border-radius: 8px;

    .table-header {
      display: flex;
      align-items: center;
      background-color: #FAFAFA;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      min-height: 48px;
      font-weight: 500;

      div {
        flex: 1;
        padding: 12px;

        &:last-of-type {
          flex: 0;
          padding: 0 40px;
        }
      }
    }

    .table-row {
      display: flex;
      align-items: center;
      min-height: 48px;
      border-top: 1px solid #E4E4E4;

      &:last-of-type {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      div {
        flex: 1;
        padding: 12px;

        &:nth-of-type(3),
        &:nth-of-type(4) {
          flex: 0;
          padding: 0;
        }

        &:last-of-type {
          padding-left: 4px;
          padding-right: 12px;
        }
      }
    }
  }
}

.delete-transformation-modal-content {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  color: #737373;

  ul {
    padding-left: 24px;
    margin-bottom: 12px;
  }
}
