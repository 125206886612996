button.elmo-btn.elmo-btn--override {
  height: 32px;
  min-width: 0;
  line-height: 14px;
  padding: 0 12px;
  border-radius: 4px;
}
button.elmo-btn.elmo-btn--override .elmo-btn__label {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
}
button.elmo-btn.elmo-btn--override:focus, button.elmo-btn.elmo-btn--override:hover, button.elmo-btn.elmo-btn--override:active, button.elmo-btn.elmo-btn--override.active {
  background-color: transparent;
}
button.elmo-btn.elmo-btn--override.elmo-btn--icon > .elmo-btn__icon {
  z-index: 1;
}
button.elmo-btn.elmo-btn--override.elmo-btn--icon > .elmo-btn__icon + .elmo-btn__label {
  margin-right: 0;
  margin-left: 8px;
}
button.elmo-btn.elmo-btn--override:hover:focus .elmo-btn__focused, button.elmo-btn.elmo-btn--override:focus:not(:focus-visible) .elmo-btn__focused {
  opacity: 0;
}
button.elmo-btn.elmo-btn--override.elmo-btn--default {
  color: #212121;
}
button.elmo-btn.elmo-btn--override.elmo-btn--default:not(.elmo-btn--is-disabled) {
  border: 1px solid #e4e4e4;
}
button.elmo-btn.elmo-btn--override.elmo-btn--default .elmo-btn__focused {
  background-color: rgba(0, 0, 0, 0.03);
}
button.elmo-btn.elmo-btn--override.elmo-btn--default .elmo-btn__hover {
  background-color: #ededed;
}
button.elmo-btn.elmo-btn--override.elmo-btn--default .elmo-btn__pressed {
  background-color: #e4e4e4;
}
button.elmo-btn.elmo-btn--override.elmo-btn--default.elmo-btn--text, button.elmo-btn.elmo-btn--override.elmo-btn--default.elmo-btn--is-text {
  border: none;
}
button.elmo-btn.elmo-btn--override.elmo-btn--default.active .elmo-btn__pressed {
  opacity: 1;
}
button.elmo-btn.elmo-btn--override.elmo-btn--primary:not(.elmo-btn--is-disabled) {
  background-color: #1765ad !important;
}
button.elmo-btn.elmo-btn--override.elmo-btn--primary .elmo-btn__focused {
  background-color: rgba(0, 0, 0, 0.15);
}
button.elmo-btn.elmo-btn--override.elmo-btn--primary .elmo-btn__hover {
  background-color: #164c7e;
}
button.elmo-btn.elmo-btn--override.elmo-btn--primary .elmo-btn__pressed {
  background-color: #15395b;
}
button.elmo-btn.elmo-btn--override.elmo-btn--is-disabled {
  color: #9E9E9E !important;
}
button.elmo-btn.elmo-btn--override.elmo-btn--is-disabled,
button.elmo-btn.elmo-btn--override.elmo-btn--is-disabled .elmo-btn__hover,
button.elmo-btn.elmo-btn--override.elmo-btn--is-disabled .elmo-btn__pressed {
  background-color: #ededed !important;
}
button.elmo-btn.elmo-btn--override.icon-btn {
  width: 32px;
  padding: 0;
}
