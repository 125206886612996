.shareReportModal {
  &, .elmo-typography {
    font-family: "Open Sans", sans-serif;
  }

  .elmo-modal__body, .elmo-modal__content {
    overflow: auto;
  }

  .elmo-modal__content {
    min-height: 520px;
  }

  .usersSelect {
    font-size: 16px;

    .react-select__menu-list {
      max-height: 225px;
    }

    .react-select__control {
      min-height: 48px;
    }

    .react-select__option {
      padding: 8px 20px;
      line-height: 20px;
    }
  }

  .usersList {
    .usersListItem {
      padding-bottom: 10px;
    }
  }

  .shareReportModal__alert {
    margin-bottom: 15px;
    font-size: 16px;

    .elmo-alert__button {
      color: #545454;

      &:hover {
        color: #fff;
      }
    }
  }
}
