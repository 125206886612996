.page-title {
  height: 40px;
  padding: 7px 15px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  margin: 0 -15px 10px;
  background: white;
}
.page-title h1 {
  font-size: 21px;
  font-weight: bold;
  margin: 0;
}
