.report-data-processing {
  display: flex;
  align-items: center;
  column-gap: 16px;

  &.is-open {
    margin-bottom: 24px;
  }

  .btn-filter {
    min-height: 32px;
    max-width: 87px;
  }

  .badge-transformation-count {
    margin-left: 8px;
    height: 20px;
    min-width: 20px;

    .elmo-badge__label {
      padding: 0;
    }
  }
}