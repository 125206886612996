@import '../../../styles/mixins';

.elmo-datatable.elmo-datatable--override {
  @media (min-width: 1024px) {
    max-height: calc(100vh - 542px);
    overflow-y: auto;

    &.elmo-alert--is-closed {
      max-height: calc(100vh - 394px);
    }
  }
  
  thead {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #ededed;
    border: none;

    th {
      border: none;
      font-size: 12px;
      line-height: 13.5px;
      height: auto;
      color: #212121;
      min-width: 0;
    }
  }

  tbody {
    tr {
      border-top: 1px solid #E4E4E4;
      border-bottom: 1px solid #E4E4E4;

      &.importedForm {
        background-color: #FAFAFA !important;
        color: #757575;
      }

      &.notImportedForm {
        background-color: transparent;
      }

      td {
        padding-top: 9px;
        padding-bottom: 9px;
        min-width: 0;

        &:nth-child(2) {
          max-width: 320px;
        }

        &:nth-child(3) {
          max-width: 160px;
        }

        .elmo-checkbox {
          top: 3px;
          left: 2px;
        }
      }
    }
  }

  nav {
    position: sticky;
    bottom: 0;
    z-index: 1;
    margin: 0;
    background: #ffffff;
    margin: 0;
    padding: 8px 8px 1px 0;

    .elmo-typography {
      font-family: 'Open Sans', sans-serif;
    }

    .elmo-btn {
      @include elmo-btn;

      .elmo-btn__hover,
      .elmo-btn__focused,
      .elmo-btn__pressed {
        border-radius: 4px;
      }

      &.elmo-btn--is-disabled {
        background-color: transparent !important;
      }
    }

    .elmo-dropdown {
      @include elmo-dropdown;

      .elmo-dropdown__button {
        box-shadow: none;
      }
    }
  }
}