.report-container {
  padding: 10px;
}
.report-container .empty {
  background: #e6eaec;
  height: 60px;
  border-top: 1px solid #dce2e5;
}
.report-container .no-data-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: 1;
  height: 100%;
  font-size: 14px;
  padding: 100px 0;
}
.report-container .no-data-found .block-icon {
  margin-bottom: 10px;
  height: 40px;
  width: 100%;
  max-height: 25%;
}
.report-container .no-data-found .block-icon .elmo-icon__svg {
  width: 100%;
  height: 100%;
}
.report-container .no-result {
  padding: 10px;
  border-style: solid;
  border-color: #dce2e5;
  border-width: 1px 0;
  background: #fff;
}
.report-container .report-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.report-container .report-header-container .report-header-buttons {
  display: flex;
}
.report-container .report-header-container .report-header-buttons .refresh-report-btn {
  margin-left: 5px;
}
.report-container .report-header-container .report-header-buttons .report-header-share-button {
  margin-left: 5px;
}
.report-container .report-header-container .report-header-buttons .cached-time {
  align-items: center;
  justify-content: center;
  display: flex;
}

.report-result {
  overflow: visible;
}
.report-result .header-col, .report-result .result-col {
  margin: 0;
  padding: 5px;
  flex-grow: 1;
  flex-basis: 0;
  min-width: 150px;
}
.report-result .header-row .header-col {
  user-select: none;
  background: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}
.report-result .header-row .column-title {
  flex-grow: 1;
}
.report-result .header-row .order {
  font-size: 10px;
  padding-right: 5px;
}
.report-result .header-row .order + .column-title {
  font-weight: bold;
}
.report-result .header-row .remove-column-btn {
  cursor: pointer;
  vertical-align: middle;
  padding-left: 5px;
  padding-right: 10px;
}
.report-result .header-row .remove-column-btn i {
  font-size: 0.7em;
}
.report-result .result-row {
  display: flex;
  overflow: visible;
  min-height: 30px;
}
.report-result .result-row:nth-of-type(even) .result-col {
  background-color: rgba(0, 0, 0, 0.05);
}
.report-result .result-row .result-col {
  word-wrap: break-word;
}

@media print {
  .cached-time {
    display: none !important;
  }
}
