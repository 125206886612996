.column-select-filter {
  font-size: 13px;
  border: solid 1px #dce2e5;
  box-shadow: inset 0 1px 1px 0 #d3d3d3;
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.column-select-filter .search-box {
  width: calc(100% - 32px);
  border: none;
  border-radius: 0;
  box-shadow: none;
  background: rgba(255, 255, 255, 0);
  /* For the search icon in the placeholder */
  font-family: "FontAwesome", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 13px;
}
.column-select-filter .search-box:focus {
  box-shadow: none;
  background: #fff;
}
.column-select-filter .search-box::placeholder {
  color: #8e8e8e;
}
.column-select-filter .clear-btn {
  margin-right: 10px;
}
