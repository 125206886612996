.selectFormModal {
  z-index: 1100 !important;

  .elmo-alert__button {
    color: #545454;
  }

  .elmo-alert {
    .elmo-row {
      .elmo-col:last-child {
        display: flex;
        align-items: center;

        .elmo-alert__title {
          padding-right: 50px;
          font-size: 16px!important;
          font-weight: normal;
        }
      }
    }
  }


  .panelContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 28px;
    margin-bottom: 28px;

    .elmo-search {
      flex: 0 0 50%;

      .elmo-search__wrapper {
        padding: 0;
      }
    }

    .viewBtn {
      flex: 1;
      padding-left: 22px;
      cursor: pointer;
    }
  }

  .tableHeader {
    background-color: #ededed;
  }
  
  .importedForm {
    background-color: #ededed !important;
  }
  
  .notImportedForm {
    background-color: transparent !important;
  }  
}

#PopoverContainer {
  position: absolute;
  z-index: 1200;
  min-width: 250px;
  padding: 10px;
}
