.manageColumnsModal .elmo-modal__body-inner {
  position: relative;
}
.manageColumnsModal .elmo-modal__body-inner .dragging-element-placeholder {
  position: absolute;
  z-index: -1;
  background-color: #EDEDED;
  border-radius: 8px;
  border: 1px solid #E4E4E4;
}
.manageColumnsModal .manage-columns-header {
  display: flex;
}
.manageColumnsModal .manage-columns-header .title {
  flex: 1;
}
.manageColumnsModal .manage-columns-header .title + div {
  display: flex;
}

.report-column__list-item {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  border: 1px solid #E4E4E4;
  border-radius: 8px;
  background-color: #FFF;
}
.report-column__list-item .report-column__list-item-handle {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 16px 24px;
  column-gap: 24px;
}
.report-column__list-item .report-column__list-item-handle > div:first-child {
  display: flex;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
}
.report-column__list-item .report-column__list-item-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 2px;
}
.report-column__list-item .report-column__list-item-subtitle {
  color: #757575;
}
.report-column__list-item .btn-settings {
  margin-right: 8px;
}

.react-tiny-popover-container {
  z-index: 5000;
}

.custom-column__default-value {
  color: #757575;
  padding: 8px 16px;
}
