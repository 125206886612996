.report-transformation {
  &.is-open {
    margin-bottom: 24px;
  }
}

div[role=cell] {
  overflow: unset;
}

div[role=grid] {
  overflow: unset;
}

div#no_overflow > div {
  overflow: unset;
}

div#no_overflow > div > div {
  overflow: unset;
}
