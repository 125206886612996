div.elmo-select.elmo-select--override {
  margin: 0;
  font-size: 14px;
}
div.elmo-select.elmo-select--override .react-select__control {
  border: 1px solid transparent;
  min-height: 30px;
  height: 30px;
}
div.elmo-select.elmo-select--override span + .react-select__control--is-focused {
  border: 1px solid #1765AD;
  box-shadow: 0 0 0 2px rgba(60, 154, 232, 0.3);
}
div.elmo-select.elmo-select--override .react-select__value-container {
  padding: 0 8px;
}
div.elmo-select.elmo-select--override .react-select__value-container > div {
  line-height: 20px;
}
