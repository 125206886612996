.userListCard {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .leftPanel {
    display: flex;
    align-items: center;
    line-height: 20px;

    .avatar {
      height: 40px;
      margin-right: 14px;
      
      img {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        border: 1px solid transparent;
      }
    }

    .fullName {
      font-weight: 700;
    }

    .email {
      color: #76777A
    }
  }
}
