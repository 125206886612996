div.elmo-alert.elmo-alert--override {
  padding: 24px;
  background: #D6E4FF;
  border: none;
  border-radius: 8px;
}
div.elmo-alert.elmo-alert--override .elmo-row .elmo-col:first-child {
  display: none;
}
div.elmo-alert.elmo-alert--override .elmo-alert__message {
  margin: 0;
}
div.elmo-alert.elmo-alert--override .elmo-alert__message--override {
  display: flex;
  column-gap: 18px;
  color: #212121;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 24px;
}
div.elmo-alert.elmo-alert--override .elmo-alert__message--override svg:first-child {
  color: #1D39C4;
  flex-shrink: 0;
}
div.elmo-alert.elmo-alert--override .elmo-alert__message--override p.elmo-alert__message {
  margin-bottom: 16px;
}
div.elmo-alert.elmo-alert--override .elmo-alert__message--override button.elmo-btn.elmo-btn--default {
  background: #ffffff;
}
