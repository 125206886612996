.column-select-nrb {
  display: flex;
  flex-direction: column;
  overflow: auto;
  border: none;
}
.column-select-nrb .header {
  display: flex;
  padding: 5px 0;
  flex-direction: row;
  font-weight: bold;
  background-color: transparent;
}
.column-select-nrb .header .title {
  flex-grow: 1;
  padding: 0 10px;
  line-height: 16px;
}
.column-select-nrb .filter {
  margin-top: 18px;
  padding: 0;
  border: none;
  background: transparent;
}
.column-select-nrb .item-group {
  margin-top: 24px;
  overflow: auto;
}
.column-select-nrb .item-group:not(:last-child) {
  min-height: 92px;
}
.column-select-nrb .item-group:not(:last-child).single {
  min-height: 62px;
}
.column-select-nrb .item-group:not(:last-child).empty {
  min-height: 32px;
}
.column-select-nrb .item-group .title {
  padding: 7px 12px;
  background: #F4F5F8;
  font-weight: 500;
  border: none;
  border-bottom: 1px solid #E4E4E4;
  line-height: 16px;
  font-size: 14px;
  position: sticky;
  top: 0;
}
.column-select-nrb .item-group .item {
  justify-content: space-between;
  align-items: center;
  padding: 7px 12px;
  line-height: 16px;
  font-size: 14px;
  color: #212121;
  cursor: pointer;
}
.column-select-nrb .item-group .item:hover {
  background: #eeeeee;
}
.column-select-nrb .item-group .item .icon {
  width: 16px;
  display: inline-block;
  margin-right: 7px;
}
.column-select-nrb .item-group .item:not(:last-child) {
  border-bottom: 1px solid #dce2e5;
}
.column-select-nrb .item-group .item.selected {
  color: #1765AD;
  background: #F0F5FF;
}
