.suggestions-container .suggestions-btn {
  padding: 6px;
  width: 34px;
  height: 34px;
}
.suggestions-container .suggestions-btn .icon-lightbulb {
  font-size: 18px;
}

.suggestions-popover {
  min-width: 250px;
  width: 20vw;
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.15);
}
.suggestions-popover .popover-header {
  background: #fff;
}
.suggestions-popover .popover-body {
  background-color: #f5f5f5;
}
.suggestions-popover .popover-body hr {
  width: calc(100% + 24px);
  margin-left: -12px;
}
