.tree-node .item {
  border-top: 1px solid #dce2e5;
  padding: 5px 10px;
  line-height: 16px;
  cursor: pointer;
}
.tree-node .item:hover {
  background: #eeeeee;
}
.tree-node .item.selected {
  background: #F6FFED;
}
.tree-node .item.disabled {
  background-color: #FAFAFA;
  color: #BDBDBD;
  cursor: default;
}
.tree-node .item .tree-icon {
  color: #9E9E9E;
  margin-right: 3px;
  width: 16px;
  font-size: 12px;
}
.tree-node .item .tree-icon .fa-check {
  color: #52C41B;
}
.tree-node .item .contentContainer .contentStatus {
  padding-left: 2px;
  font-size: 70%;
  vertical-align: super;
  color: #52C41B;
}
.tree-node.tree-node-1 {
  border-bottom: 1px solid #dce2e5;
}
.tree-node.tree-node-1 > .item {
  font-weight: bold;
}
.tree-node.tree-node-1 > .item:hover {
  background: none;
}
