@import './colours';

@mixin elmo-btn {
  height: 32px;
  min-width: 0;
  line-height: 14px;
  padding: 0 12px;
  border-radius: 4px;

  .elmo-btn__label {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
  }

  &:focus,
  &:hover,
  &:active,
  &.active {
    background-color: transparent;
  }

  &.elmo-btn--icon > .elmo-btn__icon {
    z-index: 1;

    & + .elmo-btn__label {
      margin-right: 0;
      margin-left: 8px;
    }
  }

  &:hover:focus,
  &:focus:not(:focus-visible) {
    .elmo-btn__focused {
      opacity: 0;
    }
  }

  &.elmo-btn--default {
    color: #212121;

    &:not(.elmo-btn--is-disabled) {
      border: 1px solid #e4e4e4;
    }

    .elmo-btn__focused {
      background-color: rgba(0, 0, 0, 0.03);
    }

    .elmo-btn__hover {
      background-color: #ededed;
    }

    .elmo-btn__pressed {
      background-color: #e4e4e4;
    }

    &.elmo-btn--text,
    &.elmo-btn--is-text {
      border: none;
    }

    &.active .elmo-btn__pressed {
      opacity: 1;
    }
  }

  &.elmo-btn--primary {
    &:not(.elmo-btn--is-disabled) {
      background-color: #1765ad !important;
    }

    .elmo-btn__focused {
      background-color: rgba(0, 0, 0, 0.15);
    }

    .elmo-btn__hover {
      background-color: #164c7e;
    }

    .elmo-btn__pressed {
      background-color: #15395b;
    }
  }

  &.elmo-btn--is-disabled {
    color: $caret !important;

    &,
    .elmo-btn__hover,
    .elmo-btn__pressed {
      background-color: #ededed !important;
    }
  }

  &.icon-btn {
    width: 32px;
    padding: 0;
  }
}

@mixin elmo-radio-button-group {
  width: 100%;
  border-bottom: 1px solid #d9d9d9;

  .elmo-radio {
    border: none;
    border-radius: 0 !important;
    box-shadow: initial;
    color: #757575;
    font-weight: 500;
    background-color: #fff !important;
    border-bottom: 2px solid transparent;

    .elmo-radio__label {
      color: #757575;
    }

    &:focus {
      border: none !important;
      border-bottom: 2px solid #1765ad !important;
      background-color: #fff;
      box-shadow: initial;
    }
  }

  .elmo-radio--checked {
    color: #1765ad;
    border-bottom-color: #1765ad;
    background-color: #fff;

    .elmo-radio__label {
      color: #1765ad;
    }
  }
}

@mixin elmo-dropdown {
  button.elmo-btn {
    @include elmo-btn;

    .elmo-btn__label {
      align-items: center;
    }
  }
}