.suggestion-content {
  margin-bottom: 8px;
}
.suggestion-content .selection {
  border: solid 1px #dce2e5;
  border-radius: 4px;
  background: #fff;
  padding: 5px;
  max-height: 65px;
  overflow-x: hidden;
  overflow-y: auto;
}
.suggestion-content .selection .badge {
  font-size: 11px;
  color: #212529;
  border-radius: 4px;
  background: #dff2f8;
  font-weight: normal;
  margin: 0 5px 5px 0;
  padding: 5px;
}
.suggestion-content .list-group {
  max-height: 50vh;
  overflow-y: auto;
  cursor: pointer;
}
.suggestion-content .list-group .list-group-item {
  padding: 5px;
  line-height: 16px;
  border-left: transparent;
  border-right: transparent;
  border-radius: 0;
}
.suggestion-content .list-group .list-group-item:hover {
  background: #eeeeee;
}
.suggestion-content .list-group .list-group-item .suggestion-item {
  display: flex;
  align-items: center;
}
.suggestion-content .list-group .list-group-item .icon {
  width: 16px;
  display: inline-block;
  margin-right: 7px;
  flex-shrink: 0;
}
.suggestion-content .list-group .list-group-item.suggestion-active {
  background: #F6FFED;
}
.suggestion-content .suggestion-title-with-tooltip {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  margin-right: 3px;
}
