.clearBtn {
  min-width: 0 !important;
  height: 0 !important;
  padding: 0 !important;
}
.clearBtn span {
  line-height: 14px;
}
.clearBtn:hover, .clearBtn:focus, .clearBtn:active {
  background-color: transparent !important;
}
.clearBtn:hover .clearIcon, .clearBtn:focus .clearIcon, .clearBtn:active .clearIcon {
  color: #555;
  transition: all 0.5s;
}
.clearBtn .clearIcon {
  color: #212121;
}
