.noDataComponent {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;

  .noDataMessage {
    margin: 33px 0;
    font-family: "Open Sans", sans-serif;
    color: #424448;

    .elmo-btn {
      font-family: "Open Sans", sans-serif;
    }
  }
}
