.elmo-checkbox.elmo-checkbox--override {
  width: 100%;
  display: flex;
  align-items: center;

  .elmo-checkbox__element {
    width: 16px;
    height: 16px;

    .elmo-checkbox__icon {          
      &::before {
        background-color: #FFF;
      }

      &::after {
        width: 10px;
        border-color: #FFF;
        border-width: 0 0 1.5px 1.5px;
      }
    }
  }

  .elmo-checkbox__label {
    margin-left: 8px;
    font-size: 14px;
    font-weight: 400;
  }

  &.elmo-checkbox--checked {
    .elmo-checkbox__icon {
      background-color: #1765AD;

      &::before {
        background-color: #1765AD;
      }
    }
  }

  &.elmo-checkbox--disabled {
    .elmo-checkbox__icon::before {
      background-color: #FAFAFA;
    }
  }
}