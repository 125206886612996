.dashboard .dashboard-container, .dashboard .exportPdfPreviewContainer {
  display: flex;
  flex-direction: column;
}
.dashboard .dashboard-container .widget-container, .dashboard .exportPdfPreviewContainer .widget-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: calc(100% - 1px);
  background-color: #fff;
}
.dashboard .dashboard-container .widget-container .widget-drag-container, .dashboard .exportPdfPreviewContainer .widget-container .widget-drag-container {
  height: 24px;
  text-align: center;
}
.dashboard .dashboard-container .widget-container .widget-drag-container .widget-drag-icon, .dashboard .exportPdfPreviewContainer .widget-container .widget-drag-container .widget-drag-icon {
  opacity: 0;
  transform: rotate(90deg);
  color: #757575;
}
.dashboard .dashboard-container .widget-container .widget-drag-container .widget-drag-icon:hover, .dashboard .exportPdfPreviewContainer .widget-container .widget-drag-container .widget-drag-icon:hover {
  color: #000;
  cursor: grab;
}
.dashboard .dashboard-container .widget-container .widget-drag-container .widget-drag-icon:active, .dashboard .exportPdfPreviewContainer .widget-container .widget-drag-container .widget-drag-icon:active {
  cursor: grabbing;
}
.dashboard .dashboard-container .widget-container .widget-header, .dashboard .exportPdfPreviewContainer .widget-container .widget-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 24px;
  margin: 0;
  padding: 0 16px;
}
.dashboard .dashboard-container .widget-container .widget-header h6, .dashboard .exportPdfPreviewContainer .widget-container .widget-header h6 {
  margin: 0;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dashboard .dashboard-container .widget-container .dropdown-button, .dashboard .exportPdfPreviewContainer .widget-container .dropdown-button {
  margin-right: -8px;
}
.dashboard .dashboard-container .widget-container .dropdown-button .elmo-btn.elmo-dropdown__button.elmo-btn--default.elmo-btn--icon.elmo-btn--is-text, .dashboard .exportPdfPreviewContainer .widget-container .dropdown-button .elmo-btn.elmo-dropdown__button.elmo-btn--default.elmo-btn--icon.elmo-btn--is-text {
  z-index: 1;
}
.dashboard .dashboard-container .widget-container .visualisation-container, .dashboard .exportPdfPreviewContainer .widget-container .visualisation-container {
  height: 100%;
  overflow: auto;
}
.dashboard .dashboard-container .widget-container .visualisation-container.report-style::-webkit-scrollbar, .dashboard .exportPdfPreviewContainer .widget-container .visualisation-container.report-style::-webkit-scrollbar {
  -webkit-appearance: none;
}
.dashboard .dashboard-container .widget-container .visualisation-container.report-style::-webkit-scrollbar:vertical, .dashboard .exportPdfPreviewContainer .widget-container .visualisation-container.report-style::-webkit-scrollbar:vertical {
  width: 7px;
}
.dashboard .dashboard-container .widget-container .visualisation-container.report-style::-webkit-scrollbar:horizontal, .dashboard .exportPdfPreviewContainer .widget-container .visualisation-container.report-style::-webkit-scrollbar:horizontal {
  height: 7px;
}
.dashboard .dashboard-container .widget-container .visualisation-container.report-style::-webkit-scrollbar-thumb, .dashboard .exportPdfPreviewContainer .widget-container .visualisation-container.report-style::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.dashboard .dashboard-container .widget-container .visualisation-container [data-highcharts-chart], .dashboard .exportPdfPreviewContainer .widget-container .visualisation-container [data-highcharts-chart] {
  height: 100%;
}
.dashboard .dashboard-container .widget-container .visualisation-container [data-highcharts-chart] .highcharts-container, .dashboard .exportPdfPreviewContainer .widget-container .visualisation-container [data-highcharts-chart] .highcharts-container {
  height: 100%;
}
.dashboard .dashboard-container .widget-container .visualisation-container [data-highcharts-chart] .highcharts-container .highcharts-root, .dashboard .exportPdfPreviewContainer .widget-container .visualisation-container [data-highcharts-chart] .highcharts-container .highcharts-root {
  height: 100%;
}
.dashboard .dashboard-container .widget-container .visualisation-container .report-result, .dashboard .exportPdfPreviewContainer .widget-container .visualisation-container .report-result {
  overflow: visible;
}
.dashboard .no-result {
  padding: 10px;
}
.dashboard .react-grid-item.react-grid-placeholder {
  background-color: #19579F;
  border-radius: 5px;
}

.edit-chart-icon {
  margin-right: 16px;
}

.pdfTemplateContainer {
  background-color: #888;
  font-family: "Open Sans", sans-serif;
  position: absolute;
  top: -20000px;
  left: -30000px;
  z-index: -10;
}
.pdfTemplateContainer .pdfTemplatePage {
  position: relative;
}
.pdfTemplateContainer .pdfTemplatePage .pdfTemplatePageHeader {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-color: #fff;
}
.pdfTemplateContainer .pdfTemplatePage .pdfTemplatePageHeader .pdfTemplatePageLogo {
  max-width: 250px;
  max-height: 128px;
}
.pdfTemplateContainer .pdfTemplatePage .pdfTemplatePageHeader .pdfTemplatePageInfoBlock {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}
.pdfTemplateContainer .pdfTemplatePage .pdfTemplatePageHeader .pdfTemplatePageInfoBlock .pdfTemplatePageDate {
  font-size: 18px;
  color: #666;
}
.pdfTemplateContainer .pdfTemplatePage .pdfTemplatePageHeader .pdfTemplatePageInfoBlock .pdfTemplatePageTitle {
  font-size: 22px;
  font-weight: bold;
}
.pdfTemplateContainer .pdfTemplatePage .pdfTemplateWidget {
  position: absolute;
  background-color: #fff;
  border: 2px solid #bbb;
  border-radius: 3px;
}
.pdfTemplateContainer .pdfTemplatePage .pdfTemplateWidget .widget-drag-container {
  display: none;
}
.pdfTemplateContainer .pdfTemplatePage .pdfTemplateWidget .widget-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 24px;
  margin: 24px 16px 12px;
}
.pdfTemplateContainer .pdfTemplatePage .pdfTemplateWidget .widget-header h6 {
  margin: 0;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pdfTemplateContainer .pdfTemplatePage .pdfTemplateWidget .dropdown-button {
  display: none;
}
