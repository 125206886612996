@import '../../../styles/constants';

/**
These component library style overrides are required as we have decided as part of CRT-892 to have only the LeftNav and
Tabs menu be owned by the component library styling. Everything else will be standard CRT styling until the redesign.
*/
body {
  .elmo-layout {
    &__main-content {
      padding: 20px 0;
    }

    &__main-content-wrapper {
      overflow-y: auto;
    }

    // Style overrides for the CRT components
    .page-title {
      display: none;
    }

    .reporting-tool-page, .reporting-read-only-page {
      .left-col, .right-col {
        height: calc(100vh - 64px);
        max-height: calc(100vh - 64px);
        overflow: hidden;
      }
    }

    &__main-header {
      background: white;

      .elmo-header-wrapper {
        .elmo-header__base {
          @media (max-width: 1279.9px) and (min-width: 840px) {
            padding: 8px 0;
          }
        }
      }

      .elmo-header-wrapper__spacing {
        @media (max-width: 1279.9px) and (min-width: 840px) {
          height: 112px;
        }
      }
    }

    // overriding theme.css - can remove when new theming is added
    &__main-menu {
      .elmo-btn--is-text {
        color: #545454;
        background: transparent;
        border-color: transparent;
      }
    }

    .newBuilderTrick {
      ~.elmo-layout__main-content-wrapper {
        .elmo-layout__main-content {
          padding: 0;
          height: 100%;

          .container-fluid {
            padding: 0;
            height: 100%;
          }
        }
      }
    }
  }

  .elmo-nav-drawer {
    // Remove the hover style for links that Bootstrap added.
    a:hover {
      text-decoration: inherit;
    }
  }
}
