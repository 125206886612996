div.rdp {
  button,
  & + div button {
    &:hover {
      color: #212121;
    }

    &:focus {
      color: #212121;
      background-color: #fff;
      border-color: transparent;
      outline: none;
    }

    &:focus-visible {
      outline: var(--eds-focus-ring-default);
    }

    &:disabled {
      color: #9e9e9e;
      border-color: transparent;
      background-color: transparent;
    }
  }

  [aria-label='Go back'],
  & + div button[aria-label='Today'] {
    &:focus {
      border-color: #e4e4e4;
    }
  }

  table {
    button {
      &[aria-selected='true']:not(:disabled) {
        color: #fff;

        &:hover {
          background-color: var(--eds-palette-theme-primary500);
        }
      }

      &:hover:not([aria-selected='true']) {
        background-color: transparent;
      }
    }
  }
}
