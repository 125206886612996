.column-select {
  border-style: solid;
  border-color: #dce2e5;
  border-width: 1px 0;
}
.column-select .header {
  display: flex;
  padding: 5px 10px;
  flex-direction: row;
  background: #e0e0e0;
  font-weight: bold;
}
.column-select .header .check-button {
  flex-shrink: 0;
  height: 16px;
  line-height: 16px;
  border: none;
  padding: 0;
  width: 20px;
  background: none;
  outline: none;
  cursor: pointer;
  margin-left: -3px;
  color: #212529;
}
.column-select .header .check-button .fa-check-square-o {
  color: #52C41B;
}
.column-select .header .title {
  flex-grow: 1;
  padding: 0 10px;
  line-height: 16px;
}
.column-select .filter {
  padding: 5px;
  border-style: solid;
  border-color: #dce2e5;
  border-width: 1px 0;
  background: #f5f5f5;
}
.column-select .item-group .title {
  padding: 5px;
  background: #f5f5f5;
  font-weight: bold;
  border-style: solid;
  border-color: #dce2e5;
  border-width: 1px 0;
  line-height: 16px;
}
.column-select .item-group .item {
  padding: 5px;
  line-height: 16px;
  cursor: pointer;
}
.column-select .item-group .item:hover {
  background: #eeeeee;
}
.column-select .item-group .item .icon {
  width: 16px;
  display: inline-block;
  margin-right: 7px;
}
.column-select .item-group .item:not(:last-child) {
  border-bottom: 1px solid #dce2e5;
}
.column-select .item-group .item.selected {
  background: #F6FFED;
}
