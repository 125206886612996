.clear-btn {
  display: flex;
  align-items: center;
  width: 16px;
  height: 16px;
  line-height: 8px !important;
  border-radius: 50% !important;
  color: #fff !important;
  background: #616161 !important;
  padding: 0 !important;
}
.clear-btn .icon-closed {
  font-size: 8px;
  line-height: 8px;
}
