.toggle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.toggle .react-toggle-thumb {
  background: #fff;
  border: transparent;
}
.toggle .toggle-label {
  display: flex;
  margin-left: 10px;
  align-items: center;
}
.toggle .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #dcdcdf;
}
.toggle .react-toggle--checked .react-toggle-track {
  background-color: #397af2;
}
.toggle .react-toggle-track {
  background-color: #dcdcdf;
}
.toggle .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #397af2;
}
.toggle .react-toggle--checked .react-toggle-thumb {
  border-color: #397af2;
}
.toggle .react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.toggle .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.toggle.toggle-sm .react-toggle-thumb {
  width: 16px;
  height: 16px;
  top: 2px;
  left: 2px;
}
.toggle.toggle-sm .react-toggle-track {
  width: 32px;
  height: 20px;
}
.toggle.toggle-sm .react-toggle--checked .react-toggle-thumb {
  left: 14px;
}
