.new-report-container {
  padding: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.new-report-container.no-data-container {
  transform: scale(1);
}
.new-report-container.no-data-container .no-data-found-cell {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
}
.new-report-container.no-data-container .no-data-found-cell .no-data-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: 1;
  height: 100%;
  font-size: 14px;
  padding: 60px 0;
}
.new-report-container.no-data-container .no-data-found-cell .no-data-found .header {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #212121;
}
.new-report-container.no-data-container .no-data-found-cell .no-data-found .description {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #757575;
}
.new-report-container .no-result__container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 0;
  font-family: "Open Sans", sans-serif;
}
.new-report-container .no-result__container .no-result__body .no-result__heading, .new-report-container .no-result__container .no-result__body .no-result__message {
  text-align: center;
}
.new-report-container .no-result__container .no-result__body .no-result__heading {
  margin-bottom: 16px;
  font-size: 20px !important;
  font-weight: 700;
}
.new-report-container .no-result__container .no-result__body .no-result__message {
  font-size: 16px;
  color: #757575;
}
.new-report-container .report-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 62px;
}
.new-report-container .report-header-container h4 {
  font-size: 16px !important;
  font-weight: 700;
  margin-left: 15px;
}
.new-report-container .report-header-container .report-header-buttons {
  display: flex;
  align-items: center;
  column-gap: 16px;
  margin: 15px;
}
.new-report-container .report-header-container .report-header-buttons .refresh-report-btn {
  margin-left: 5px;
}
.new-report-container .report-header-container .report-header-buttons .cached-time {
  align-items: center;
  justify-content: center;
  display: flex;
}

.new-report-result {
  overflow: auto;
  max-height: calc(100vh - 180px);
  width: 100%;
  flex: 1;
}
.new-report-result table {
  width: 100%;
}
.new-report-result thead {
  position: sticky;
  top: 0;
  z-index: 0;
}
.new-report-result .header-col, .new-report-result .result-col {
  margin: 0;
  padding: 14px 16px 16px 16px;
  background: #FAFAFA;
  flex-grow: 1;
  flex-basis: 0;
  min-width: 160px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #212121;
}
.new-report-result .header-col .drilldown-icon, .new-report-result .result-col .drilldown-icon {
  display: inline-block;
}
.new-report-result .header-col .drilldown-icon span, .new-report-result .result-col .drilldown-icon span {
  margin-left: 8px;
  font-size: 18px;
  line-height: 14px;
  vertical-align: baseline;
  cursor: pointer;
}
.new-report-result .header-col .drilldown-icon span .elmo-icon__svg, .new-report-result .result-col .drilldown-icon span .elmo-icon__svg {
  font-size: 14px;
}
.new-report-result .header-row .header-col {
  user-select: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  padding: 11px 16px;
  column-gap: 8px;
  background: #FAFAFA;
  border-bottom: 1px solid #E4E4E4;
  border-radius: 0;
}
.new-report-result .header-row .header-col :first-child {
  flex: 1;
}
.new-report-result .header-row .header-col.no-pointer {
  cursor: auto;
}
.new-report-result .header-row .order {
  display: flex;
}
.new-report-result .result-row {
  display: flex;
  overflow: visible;
  min-height: 30px;
}
.new-report-result .result-row:nth-of-type(odd) .result-col {
  background-color: #fff;
}
.new-report-result .result-row .result-col {
  display: flex;
  align-items: center;
  word-break: break-word;
}

@media print {
  .cached-time {
    display: none !important;
  }
}
