.new-generate-chart .elmo-radio.elmo-radio--disabled {
  background-color: #E6E6E7;
}

.chart-icons button {
  width: 32px;
  height: 32px;
  margin-right: 4px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.chart-icons button:not(.icon-active) {
  background-color: #fff;
  color: #212529;
  border-color: #dce2e5;
}
.chart-icons button .icon {
  font-size: 18px;
  line-height: 18px;
}
