.tooltip-wrapper {
  display: inline-block;
  border: 1px solid #c5c5c5;
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  border-radius: 50%;
  background: #fff;
}
.tooltip-wrapper .icon-tooltips {
  font-size: 14px;
  vertical-align: top;
  line-height: 14px;
}
